<!-- Modal to control cancellation of a batch or statement. -->
<template>
  <v-dialog justify="center" :value="value" persistent max-width="400">
    <v-card color="#fafafa">
      <section class="ma-0 pa-6">
        <v-row justify="center">
          <v-icon x-large color="red">mdi-cancel-outline</v-icon>
        </v-row>
        <v-row justify="center">
          <span class="pa-4"> Cancel "{{ item.name }}"? </span>
          <span class="pa-4">
            Last Updated: {{ formatDateTime(item.last_updated) }}
          </span>
        </v-row>
        <v-row justify="center" class="pa-4">
          <v-btn
            height="40"
            class="elevation-1"
            @click.stop="$emit('update:value', false)"
          >
            No
          </v-btn>
          <v-btn
            color="#0c67a5"
            height="40"
            class="white--text ml-2"
            @click="cancelItem(item.id)"
            >Yes
          </v-btn>
        </v-row>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import BillingBatch from "@/axios/billing-batch";
import Statements from "@/axios/statements";
import { dateFormat } from "@/mixins/date-format";
export default {
  name: "ConfirmCancel",

  props: ["value", "selectedBatch", "selectedStatement"],

  mixins: [dateFormat],
  methods: {
    async cancelItem(id) {
      this.$store.dispatch("setSnackbar", {
        status: "alert",
        text: `Canceling ${this.item.name}, Please Wait`,
      });

      try {
        await this.item.cancel(id);
        this.$emit("update:value", false);
        this.$store.dispatch("setSnackbar", {
          status: "success",
          text: `Canceled ${this.item.name} Successfully`,
        });
      } catch (err) {
        this.$store.dispatch("setSnackbar", {
          status: "alert",
          text: `Failed to cancel ${this.item.name}: ${err.message}`,
        });
      }
    },
  },
  computed: {
    item() {
      if (this.selectedBatch) {
        return {
          name: this.selectedBatch.name,
          id: this.selectedBatch.id,
          last_updated: this.selectedBatch.last_update_date,
          cancel: async (id) => {
            return BillingBatch.cancelBatch(id);
          },
        };
      } else {
        return {
          name: this.selectedStatement.name,
          id: this.selectedStatement.statement_id,
          last_updated: this.selectedStatement.updated_on,
          cancel: async (id) => {
            return Statements.cancelStatement(id);
          },
        };
      }
    },
  },
};
</script>
